exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-style-guide-js": () => import("./../../../src/pages/style-guide.js" /* webpackChunkName: "component---src-pages-style-guide-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-01-15-ano-nuevo-linux-nuevo-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-01-15-ano-nuevo-linux-nuevo/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-01-15-ano-nuevo-linux-nuevo-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-01-15-traslado-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-01-15-traslado/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-01-15-traslado-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-01-15-trasteando-con-gcc-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-01-15-trasteando-con-gcc/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-01-15-trasteando-con-gcc-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-01-16-dos-peliculas-y-un-documental-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-01-16-dos-peliculas-y-un-documental/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-01-16-dos-peliculas-y-un-documental-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-01-17-webcam-en-debian-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-01-17-webcam-en-debian/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-01-17-webcam-en-debian-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-01-18-drivers-para-wifi-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-01-18-drivers-para-wifi/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-01-18-drivers-para-wifi-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-01-29-cam-por-linea-de-comandos-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-01-29-cam-por-linea-de-comandos/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-01-29-cam-por-linea-de-comandos-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-01-30-direcciones-ip-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-01-30-direcciones-ip/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-01-30-direcciones-ip-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-02-01-antena-wi-fi-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-02-01-antena-wi-fi/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-02-01-antena-wi-fi-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-02-04-google-toolbar-en-iceweasel-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-02-04-google-toolbar-en-iceweasel/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-02-04-google-toolbar-en-iceweasel-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-02-05-google-earth-en-linux-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-02-05-google-earth-en-linux/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-02-05-google-earth-en-linux-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-02-08-tab-en-la-consola-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-02-08-tab-en-la-consola/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-02-08-tab-en-la-consola-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-02-13-ktechlab-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-02-13-ktechlab/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-02-13-ktechlab-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-02-14-festival-charlando-con-mi-pc-parte-1-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-02-14-festival-charlando-con-mi-pc-parte-1/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-02-14-festival-charlando-con-mi-pc-parte-1-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-02-21-lowfat-otra-forma-de-ver-las-fotos-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-02-21-lowfat-otra-forma-de-ver-las-fotos/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-02-21-lowfat-otra-forma-de-ver-las-fotos-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-03-06-muestras-de-microchip-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-03-06-muestras-de-microchip/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-03-06-muestras-de-microchip-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-03-23-otra-vez-por-aqui-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-03-23-otra-vez-por-aqui/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-03-23-otra-vez-por-aqui-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-04-17-fuente-de-laboratorio-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-04-17-fuente-de-laboratorio/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-04-17-fuente-de-laboratorio-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-04-18-1024-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-04-18-1024/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-04-18-1024-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-05-29-comics-de-star-wars-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-05-29-comics-de-star-wars/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-05-29-comics-de-star-wars-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-06-06-multitouch-pad-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-06-06-multitouch-pad/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-06-06-multitouch-pad-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-06-18-trasteando-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-06-18-trasteando/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-06-18-trasteando-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-07-25-mas-comics-de-star-wars-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-07-25-mas-comics-de-star-wars/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-07-25-mas-comics-de-star-wars-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-07-28-10-razones-por-las-que-alejar-a-tu-novia-de-un-geek-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-07-28-10-razones-por-las-que-alejar-a-tu-novia-de-un-geek/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-07-28-10-razones-por-las-que-alejar-a-tu-novia-de-un-geek-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-08-12-como-volver-a-la-configuracion-original-de-un-escritorio-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-08-12-como-volver-a-la-configuracion-original-de-un-escritorio/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-08-12-como-volver-a-la-configuracion-original-de-un-escritorio-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-08-14-instalado-compiz-fusion-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-08-14-instalado-compiz-fusion/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-08-14-instalado-compiz-fusion-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-08-18-lowfat-en-un-deb-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-08-18-lowfat-en-un-deb/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-08-18-lowfat-en-un-deb-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-08-21-mas-plugins-para-compiz-fusion-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-08-21-mas-plugins-para-compiz-fusion/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-08-21-mas-plugins-para-compiz-fusion-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-08-24-mas-temas-para-emerald-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-08-24-mas-temas-para-emerald/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-08-24-mas-temas-para-emerald-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-09-04-opera-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-09-04-opera/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-09-04-opera-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-09-25-manual-basico-de-proteus-isis-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-09-25-manual-basico-de-proteus-isis/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-09-25-manual-basico-de-proteus-isis-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-10-10-litoxconf-10-mi-script-de-configuracion-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-10-10-litoxconf-10-mi-script-de-configuracion/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-10-10-litoxconf-10-mi-script-de-configuracion-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-10-15-los-ultimos-pluggins-de-compiz-fusion-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-10-15-los-ultimos-pluggins-de-compiz-fusion/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-10-15-los-ultimos-pluggins-de-compiz-fusion-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-11-27-portableapps-y-wine-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-11-27-portableapps-y-wine/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-11-27-portableapps-y-wine-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-12-11-reinstalando-grub-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2008-12-11-reinstalando-grub/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2008-12-11-reinstalando-grub-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-03-01-gmail-con-imap-en-thunderbird-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-03-01-gmail-con-imap-en-thunderbird/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-03-01-gmail-con-imap-en-thunderbird-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-03-21-particion-ntfs-en-debian-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-03-21-particion-ntfs-en-debian/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-03-21-particion-ntfs-en-debian-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-03-21-vuelta-a-debian-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-03-21-vuelta-a-debian/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-03-21-vuelta-a-debian-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-03-25-cazadores-de-mitos-el-mito-del-alunizaje-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-03-25-cazadores-de-mitos-el-mito-del-alunizaje/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-03-25-cazadores-de-mitos-el-mito-del-alunizaje-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-03-25-sudo-en-debian-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-03-25-sudo-en-debian/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-03-25-sudo-en-debian-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-03-31-debian-desde-instalacion-base-i-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-03-31-debian-desde-instalacion-base-i/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-03-31-debian-desde-instalacion-base-i-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-04-02-debian-desde-instalacion-base-ii-sistema-grafico-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-04-02-debian-desde-instalacion-base-ii-sistema-grafico/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-04-02-debian-desde-instalacion-base-ii-sistema-grafico-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-04-05-debian-desde-instalacion-base-iii-kde-4-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-04-05-debian-desde-instalacion-base-iii-kde4/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-04-05-debian-desde-instalacion-base-iii-kde-4-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-04-09-debian-desde-instalacion-base-iv-programas-basicos-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-04-09-debian-desde-instalacion-base-iv-programas-basicos/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-04-09-debian-desde-instalacion-base-iv-programas-basicos-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-04-11-adobe-reader-en-debian-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-04-11-adobe-reader-en-debian/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-04-11-adobe-reader-en-debian-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-04-13-debian-desde-instalacion-base-v-audio-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-04-13-debian-desde-instalacion-base-v-audio/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-04-13-debian-desde-instalacion-base-v-audio-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-04-13-iconos-windows-en-linux-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-04-13-iconos-windows-en-linux/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-04-13-iconos-windows-en-linux-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-04-14-pikdev-para-kde-4-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-04-14-pikdev-para-kde4/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-04-14-pikdev-para-kde-4-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-04-22-apt-file-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-04-22-apt-file/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-04-22-apt-file-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-04-28-compiz-y-kde-4-en-debian-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-04-28-compiz-y-kde4-en-debian/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-04-28-compiz-y-kde-4-en-debian-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-05-23-sensores-de-temperatura-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-05-23-sensores-de-temperatura/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-05-23-sensores-de-temperatura-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-06-09-proyecto-natal-de-xbox-360-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-06-09-proyecto-natal-de-xbox-360/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-06-09-proyecto-natal-de-xbox-360-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-06-21-html-5-y-el-video-libre-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-06-21-html-5-y-el-video-libre/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-06-21-html-5-y-el-video-libre-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-06-23-firefox-3-5-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-06-23-firefox-3-5/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-06-23-firefox-3-5-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-06-25-wii-motion-plus-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-06-25-wii-motion-plus/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-06-25-wii-motion-plus-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-07-02-copiar-una-particion-a-otra-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-07-02-copiar-una-particion-a-otra/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-07-02-copiar-una-particion-a-otra-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-07-16-cambio-de-look-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-07-16-cambio-de-look/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-07-16-cambio-de-look-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-08-12-nuevos-temas-para-emerald-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-08-12-nuevos-temas-para-emerald/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-08-12-nuevos-temas-para-emerald-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-08-13-marcianitos-ya-no-existe-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-08-13-marcianitos-ya-no-existe/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-08-13-marcianitos-ya-no-existe-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-08-18-base-para-portatil-i-parte-especificaciones-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-08-18-base-para-portatil-i-parte-especificaciones/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-08-18-base-para-portatil-i-parte-especificaciones-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-08-23-debian-lenny-con-xfce-4-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-08-23-debian-lenny-con-xfce4/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-08-23-debian-lenny-con-xfce-4-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-08-31-base-para-portatil-ii-especificaciones-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-08-31-base-para-portatil-ii-especificaciones/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-08-31-base-para-portatil-ii-especificaciones-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-09-01-rtl-8187-b-y-wicd-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-09-01-rtl8187b-y-wicd/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-09-01-rtl-8187-b-y-wicd-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-09-22-introduccion-al-hardware-libre-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-09-22-introduccion-al-hardware-libre/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-09-22-introduccion-al-hardware-libre-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-11-01-hardware-libre-i-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-11-01-hardware-libre-i/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-11-01-hardware-libre-i-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-11-14-xorg-conf-de-grafica-nvidia-geforce-fx-5700-ve-y-monitor-suyama-17-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-11-14-xorg-conf-de-grafica-nvidia-geforce-fx-5700ve-y-monitor-suyama-17/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-11-14-xorg-conf-de-grafica-nvidia-geforce-fx-5700-ve-y-monitor-suyama-17-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-12-03-manifiesto-de-los-derechos-fundamentales-de-internet-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-12-03-manifiesto-de-los-derechos-fundamentales-de-internet/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-12-03-manifiesto-de-los-derechos-fundamentales-de-internet-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-12-05-disenadores-y-patentes-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-12-05-disenadores-y-patentes/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-12-05-disenadores-y-patentes-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-12-19-defendiendo-tu-diseno-abierto-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2009-12-19-defendiendo-tu-diseno-abierto/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2009-12-19-defendiendo-tu-diseno-abierto-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-01-02-bios-y-errores-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-01-02-bios-y-errores/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-01-02-bios-y-errores-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-01-09-hardware-no-es-software-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-01-09-hardware-no-es-software/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-01-09-hardware-no-es-software-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-01-13-de-vuelta-las-muestras-de-microchip-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-01-13-de-vuelta-las-muestras-de-microchip/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-01-13-de-vuelta-las-muestras-de-microchip-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-01-15-play-ogg-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-01-15-play-ogg/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-01-15-play-ogg-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-01-15-stop-software-patents-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-01-15-stop-software-patents/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-01-15-stop-software-patents-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-01-17-tambien-red-libre-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-01-17-tambien-red-libre/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-01-17-tambien-red-libre-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-01-25-cuando-todo-sea-google-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-01-25-cuando-todo-sea-google/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-01-25-cuando-todo-sea-google-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-01-26-dropbox-en-kde-y-xfce-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-01-26-dropbox-en-kde-y-xfce/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-01-26-dropbox-en-kde-y-xfce-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-02-08-mi-feevy-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-02-08-mi-feevy/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-02-08-mi-feevy-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-02-11-cuil-de-los-creadores-de-google-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-02-11-cuil-de-los-creadores-de-google/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-02-11-cuil-de-los-creadores-de-google-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-02-13-ideas-libres-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-02-13-ideas-libres/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-02-13-ideas-libres-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-02-20-microsoft-y-sanidad-valenciana-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-02-20-microsoft-y-sanidad-valenciana/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-02-20-microsoft-y-sanidad-valenciana-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-02-22-openid-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-02-22-openid/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-02-22-openid-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-02-25-identi-ca-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-02-25-identi-ca/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-02-25-identi-ca-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-03-01-thunderbird-sincronizado-con-dropbox-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-03-01-thunderbird-sincronizado-con-dropbox/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-03-01-thunderbird-sincronizado-con-dropbox-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-03-11-google-no-es-malo-pero-empieza-a-dar-miedo-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-03-11-google-no-es-malo-pero-empieza-a-dar-miedo/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-03-11-google-no-es-malo-pero-empieza-a-dar-miedo-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-04-02-il-estandares-de-almentacion-i-poe-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-04-02-il-estandares-de-almentacion-i-poe/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-04-02-il-estandares-de-almentacion-i-poe-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-04-02-ladrones-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-04-02-ladrones/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-04-02-ladrones-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-04-29-libertad-y-privacidad-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-04-29-libertad-y-privacidad/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-04-29-libertad-y-privacidad-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-05-09-open-street-map-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-05-09-open-street-map/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-05-09-open-street-map-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-05-29-la-evolucion-del-p-2-p-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-05-29-la-evolucion-del-p2p/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-05-29-la-evolucion-del-p-2-p-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-06-08-face-to-facef-2-f-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-06-08-face-to-facef2f/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-06-08-face-to-facef-2-f-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-08-18-diferencia-entre-identificacion-y-autorizacion-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-08-18-diferencia-entre-identificacion-y-autorizacion/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-08-18-diferencia-entre-identificacion-y-autorizacion-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-08-21-hard-sci-fi-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-08-21-hard-sci-fi/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-08-21-hard-sci-fi-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-10-07-modding-y-overclock-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-10-07-modding-y-overclock/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-10-07-modding-y-overclock-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-11-30-super-navegadores-vs-app-stores-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-11-30-super-navegadores-vs-app-stores/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-11-30-super-navegadores-vs-app-stores-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-12-10-telefonica-le-quiere-sacar-los-cuartos-a-google-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-12-10-telefonica-le-quiere-sacar-los-cuartos-a-google/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-12-10-telefonica-le-quiere-sacar-los-cuartos-a-google-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-12-11-el-futuro-de-la-exploracion-espacial-esta-en-las-empresas-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2010-12-11-el-futuro-de-la-exploracion-espacial-esta-en-las-empresas/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2010-12-11-el-futuro-de-la-exploracion-espacial-esta-en-las-empresas-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-01-10-comprar-tirar-comprar-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2011-01-10-comprar-tirar-comprar/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-01-10-comprar-tirar-comprar-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-01-28-cuando-el-gobierno-te-censura-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2011-01-28-cuando-el-gobierno-te-censura/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-01-28-cuando-el-gobierno-te-censura-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-02-16-somos-cyborgs-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2011-02-16-somos-cyborgs/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-02-16-somos-cyborgs-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-02-22-anonymous-y-la-operacio-valencia-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2011-02-22-anonymous-y-la-operacio-valencia/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-02-22-anonymous-y-la-operacio-valencia-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-02-23-arthur-c-clarke-y-el-egovernment-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2011-02-23-arthur-c-clarke-y-el-egovernment/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-02-23-arthur-c-clarke-y-el-egovernment-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-03-13-e-administracion-y-dnie-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2011-03-13-e-administracion-y-dnie/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-03-13-e-administracion-y-dnie-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-03-15-crisis-nuclear-en-japon-y-energias-renovables-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2011-03-15-crisis-nuclear-en-japon-y-energias-renovables/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-03-15-crisis-nuclear-en-japon-y-energias-renovables-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-05-16-cronica-de-la-15-mani-en-valencia-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2011-05-16-cronica-de-la-15mani-en-valencia/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-05-16-cronica-de-la-15-mani-en-valencia-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-05-22-una-visita-a-acampada-valencia-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2011-05-22-una-visita-a-acampada-valencia/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-05-22-una-visita-a-acampada-valencia-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-07-06-iniciandome-en-open-street-map-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2011-07-06-iniciandome-en-open-street-map/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-07-06-iniciandome-en-open-street-map-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-07-26-los-dinosaurios-del-copyright-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2011-07-26-los-dinosaurios-del-copyright/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-07-26-los-dinosaurios-del-copyright-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-09-14-el-parlamento-europeo-extiende-el-copyright-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2011-09-14-el-parlamento-europeo-extiende-el-copyright/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-09-14-el-parlamento-europeo-extiende-el-copyright-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-10-31-forks-libres-de-android-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2011-10-31-forks-libres-de-android/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-10-31-forks-libres-de-android-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-10-31-operacion-20-n-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2011-10-31-operacion-20n/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2011-10-31-operacion-20-n-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-02-23-owncloud-3-y-mas-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-02-23-owncloud-3-y-mas/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-02-23-owncloud-3-y-mas-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-02-26-todos-a-borrar-nuestros-datos-de-google-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-02-26-todos-a-borrar-nuestros-datos-de-google/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-02-26-todos-a-borrar-nuestros-datos-de-google-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-03-01-el-blog-convertido-en-pizarra-12-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-03-01-el-blog-convertido-en-pizarra-12/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-03-01-el-blog-convertido-en-pizarra-12-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-03-19-los-servidores-en-los-cielos-de-tpb-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-03-19-los-servidores-en-los-cielos-de-tpb/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-03-19-los-servidores-en-los-cielos-de-tpb-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-03-28-cambio-de-licencia-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-03-28-cambio-de-licencia/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-03-28-cambio-de-licencia-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-04-07-radio-definida-por-software-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-04-07-radio-definida-por-software/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-04-07-radio-definida-por-software-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-04-15-inteligencia-artificial-y-las-macroempresas-de-la-informacion-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-04-15-inteligencia-artificial-y-las-macroempresas-de-la-informacion/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-04-15-inteligencia-artificial-y-las-macroempresas-de-la-informacion-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-04-19-entrevista-a-eben-upton-sobre-raspberry-pi-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-04-19-entrevista-a-eben-upton-sobre-raspberry-pi/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-04-19-entrevista-a-eben-upton-sobre-raspberry-pi-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-04-21-open-circuits-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-04-21-open-circuits/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-04-21-open-circuits-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-04-27-videovigilancia-en-valencia-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-04-27-videovigilancia-en-valencia/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-04-27-videovigilancia-en-valencia-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-05-01-novedades-en-owncloud-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-05-01-novedades-en-owncloud/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-05-01-novedades-en-owncloud-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-05-29-explicacion-de-depuradores-por-genbeta-dev-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-05-29-explicacion-de-depuradores-por-genbeta-dev/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-05-29-explicacion-de-depuradores-por-genbeta-dev-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-06-07-europa-seguridad-publica-o-xenofobia-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-06-07-europa-seguridad-publica-o-xenofobia/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-06-07-europa-seguridad-publica-o-xenofobia-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-06-16-mantenimiento-proactivo-y-desarrollo-agil-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-06-16-mantenimiento-proactivo-y-desarrollo-agil/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-06-16-mantenimiento-proactivo-y-desarrollo-agil-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-06-20-fabricacion-de-placas-base-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-06-20-fabricacion-de-placas-base/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-06-20-fabricacion-de-placas-base-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-06-26-open-garden-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-06-26-open-garden/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-06-26-open-garden-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-06-30-openid-o-browserid-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-06-30-openid-o-browserid/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-06-30-openid-o-browserid-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-07-14-estandares-de-alimentacion-ii-microusb-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-07-14-estandares-de-alimentacion-ii-microusb/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-07-14-estandares-de-alimentacion-ii-microusb-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-07-22-metodo-livingstone-e-historias-de-usuario-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-07-22-metodo-livingstone-e-historias-de-usuario/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-07-22-metodo-livingstone-e-historias-de-usuario-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-07-29-hasta-100-w-por-usb-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-07-29-hasta-100w-por-usb/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-07-29-hasta-100-w-por-usb-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-08-03-raspberry-pi-en-tiempo-real-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-08-03-raspberry-pi-en-tiempo-real/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-08-03-raspberry-pi-en-tiempo-real-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-08-05-quieres-libertad-de-expresion-abre-tu-blog-y-ganatela-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-08-05-quieres-libertad-de-expresion-abre-tu-blog-y-ganatela/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-08-05-quieres-libertad-de-expresion-abre-tu-blog-y-ganatela-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-08-15-transparencia-en-ayuntamientos-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-08-15-transparencia-en-ayuntamientos/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-08-15-transparencia-en-ayuntamientos-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-08-16-de-vuelta-a-ubuntu-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-08-16-de-vuelta-a-ubuntu/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-08-16-de-vuelta-a-ubuntu-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-08-26-diseno-modular-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-08-26-diseno-modular/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-08-26-diseno-modular-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-09-09-el-modo-de-produccion-p-2-p-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-09-09-el-modo-de-produccion-p2p/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-09-09-el-modo-de-produccion-p-2-p-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-09-13-lectura-masiva-de-noticias-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-09-13-lectura-masiva-de-noticias/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-09-13-lectura-masiva-de-noticias-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-09-14-personalizacion-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-09-14-personalizacion/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-09-14-personalizacion-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-09-27-cosas-que-hacer-despues-de-instalar-ubuntu-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-09-27-cosas-que-hacer-despues-de-instalar-ubuntu/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-09-27-cosas-que-hacer-despues-de-instalar-ubuntu-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-10-13-jornada-sobre-gestion-eficiente-de-la-energia-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-10-13-jornada-sobre-gestion-eficiente-de-la-energia/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-10-13-jornada-sobre-gestion-eficiente-de-la-energia-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-10-31-recomendaciones-para-instalar-matlab-en-linux-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-10-31-recomendaciones-para-instalar-matlab-en-linux/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-10-31-recomendaciones-para-instalar-matlab-en-linux-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-11-18-red-de-hogar-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-11-18-red-de-hogar/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-11-18-red-de-hogar-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-12-08-soluciones-in-house-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-12-08-soluciones-in-house/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-12-08-soluciones-in-house-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-12-21-camaras-como-sensores-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-12-21-camaras-como-sensores/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-12-21-camaras-como-sensores-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-12-22-electrolitoblog-nuevo-blog-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-12-22-electrolitoblog-nuevo-blog/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-12-22-electrolitoblog-nuevo-blog-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-12-30-los-numeros-de-2012-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2012-12-30-los-numeros-de-2012/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2012-12-30-los-numeros-de-2012-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-01-01-openstructures-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-01-01-openstructures/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-01-01-openstructures-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-01-04-diseno-modular-en-arduino-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-01-04-diseno-modular-en-arduino/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-01-04-diseno-modular-en-arduino-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-01-10-el-mito-del-hogar-i-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-01-10-el-mito-del-hogar-i/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-01-10-el-mito-del-hogar-i-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-01-20-montemos-una-jamstorming-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-01-20-montemos-una-jamstorming/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-01-20-montemos-una-jamstorming-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-01-25-electronica-copyleft-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-01-25-electronica-copyleft/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-01-25-electronica-copyleft-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-01-26-product-lifecycle-management-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-01-26-product-lifecycle-management/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-01-26-product-lifecycle-management-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-01-27-hermite-bezier-b-spline-y-cacaos-mentales-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-01-27-hermite-bezier-b-spline-y-cacaos-mentales/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-01-27-hermite-bezier-b-spline-y-cacaos-mentales-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-02-02-el-mito-del-hogar-ii-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-02-02-el-mito-del-hogar-ii/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-02-02-el-mito-del-hogar-ii-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-02-03-ecuaciones-en-wordpress-com-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-02-03-ecuaciones-en-wordpress-com/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-02-03-ecuaciones-en-wordpress-com-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-02-06-building-information-modeling-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-02-06-building-information-modeling/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-02-06-building-information-modeling-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-02-09-devolvednos-nuestro-historial-medico-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-02-09-devolvednos-nuestro-historial-medico/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-02-09-devolvednos-nuestro-historial-medico-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-02-14-estreno-biblioteca-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-02-14-estreno-biblioteca/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-02-14-estreno-biblioteca-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-02-16-aerodinamica-y-turbinas-en-edificios-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-02-16-aerodinamica-y-turbinas-en-edificios/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-02-16-aerodinamica-y-turbinas-en-edificios-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-03-10-modelado-de-un-edificio-i-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-03-10-modelado-de-un-edificio-i/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-03-10-modelado-de-un-edificio-i-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-03-14-sincronizacion-de-agregadores-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-03-14-sincronizacion-de-agregadores/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-03-14-sincronizacion-de-agregadores-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-03-29-por-que-elegi-automatica-y-no-electronica-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-03-29-por-que-elegi-automatica-y-no-electronica/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-03-29-por-que-elegi-automatica-y-no-electronica-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-04-04-biodiesel-biogas-y-otros-productos-diy-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-04-04-biodiesel-biogas-y-otros-productos-diy/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-04-04-biodiesel-biogas-y-otros-productos-diy-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-04-20-defendamos-la-web-no-al-drm-en-html-5-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-04-20-defendamos-la-web-no-al-drm-en-html5/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-04-20-defendamos-la-web-no-al-drm-en-html-5-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-05-04-ifttt-como-controlador-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-05-04-ifttt-como-controlador/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-05-04-ifttt-como-controlador-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-05-12-estrenamos-blog-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-05-12-estrenamos-blog/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-05-12-estrenamos-blog-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-05-27-se-va-a-convertir-este-en-un-blog-de-control-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-05-27-se-va-a-convertir-este-en-un-blog-de-control/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-05-27-se-va-a-convertir-este-en-un-blog-de-control-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-06-05-para-vender-en-internet-necesitas-un-relato-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-06-05-para-vender-en-internet-necesitas-un-relato/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-06-05-para-vender-en-internet-necesitas-un-relato-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-06-08-copiar-el-modelo-app-store-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-06-08-copiar-el-modelo-app-store/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-06-08-copiar-el-modelo-app-store-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-06-09-comunicaciones-en-microcontroladores-un-ejemplo-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-06-09-comunicaciones-en-microcontroladores-un-ejemplo/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-06-09-comunicaciones-en-microcontroladores-un-ejemplo-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-06-18-el-hogar-mutidimensional-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-06-18-el-hogar-mutidimensional/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-06-18-el-hogar-mutidimensional-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-06-24-curso-de-control-1-introduccion-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-06-24-curso-de-control-1-introduccion/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-06-24-curso-de-control-1-introduccion-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-07-01-curso-de-control-2-estrategias-en-ingenieria-de-sistemas-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-07-01-curso-de-control-2-estrategias-en-ingenieria-de-sistemas/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-07-01-curso-de-control-2-estrategias-en-ingenieria-de-sistemas-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-07-03-sistemas-procesos-y-plantas-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-07-03-sistemas-procesos-y-plantas/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-07-03-sistemas-procesos-y-plantas-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-07-05-puesta-a-punto-de-raspberry-pi-con-noobs-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-07-05-puesta-a-punto-de-raspberry-pi-con-noobs/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-07-05-puesta-a-punto-de-raspberry-pi-con-noobs-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-07-08-curso-de-control-3-el-tiempo-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-07-08-curso-de-control-3-el-tiempo/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-07-08-curso-de-control-3-el-tiempo-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-07-15-curso-de-control-4-el-error-y-la-realimentacion-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-07-15-curso-de-control-4-el-error-y-la-realimentacion/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-07-15-curso-de-control-4-el-error-y-la-realimentacion-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-07-17-internet-y-ssh-en-raspberry-pi-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-07-17-internet-y-ssh-en-raspberry-pi/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-07-17-internet-y-ssh-en-raspberry-pi-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-07-21-esclavismo-energetico-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-07-21-esclavismo-energetico/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-07-21-esclavismo-energetico-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-07-22-nuestra-nube-en-raspberry-pi-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-07-22-nuestra-nube-en-raspberry-pi/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-07-22-nuestra-nube-en-raspberry-pi-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-07-29-curso-de-control-6-controladores-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-07-29-curso-de-control-6-controladores/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-07-29-curso-de-control-6-controladores-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-07-31-pon-ipv-6-en-tu-raspberry-pi-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-07-31-pon-ipv6-en-tu-raspberry-pi/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-07-31-pon-ipv-6-en-tu-raspberry-pi-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-08-05-curso-de-control-6-instalacion-de-octave-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-08-05-curso-de-control-6-instalacion-de-octave/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-08-05-curso-de-control-6-instalacion-de-octave-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-08-06-timo-de-los-de-ahora-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-08-06-timo-de-los-de-ahora/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-08-06-timo-de-los-de-ahora-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-08-12-curso-de-control-7-ajuste-de-un-controlador-p-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-08-12-curso-de-control-7-ajuste-de-un-controlador-p/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-08-12-curso-de-control-7-ajuste-de-un-controlador-p-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-08-16-micro-exploracion-espacial-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-08-16-micro-exploracion-espacial/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-08-16-micro-exploracion-espacial-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-08-20-curso-de-control-8-ajuste-de-un-controlador-pd-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-08-20-curso-de-control-8-ajuste-de-un-controlador-pd/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-08-20-curso-de-control-8-ajuste-de-un-controlador-pd-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-08-26-curso-de-control-9-ajuste-de-un-controlador-pi-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-08-26-curso-de-control-9-ajuste-de-un-controlador-pi/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-08-26-curso-de-control-9-ajuste-de-un-controlador-pi-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-09-09-curso-de-control-10-ajuste-de-un-controlador-pid-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-09-09-curso-de-control-10-ajuste-de-un-controlador-pid/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-09-09-curso-de-control-10-ajuste-de-un-controlador-pid-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-09-16-curso-de-control-11-controlando-nuevos-sistemas-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-09-16-curso-de-control-11-controlando-nuevos-sistemas/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-09-16-curso-de-control-11-controlando-nuevos-sistemas-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-10-01-bienvenido-muestras-everywhere-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-10-01-bienvenido-muestras-everywhere/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-10-01-bienvenido-muestras-everywhere-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-10-02-estreno-blogroll-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-10-02-estreno-blogroll/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-10-02-estreno-blogroll-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-10-15-poniendo-a-punto-xbmc-en-raspberry-pi-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-10-15-poniendo-a-punto-xbmc-en-raspberry-pi/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-10-15-poniendo-a-punto-xbmc-en-raspberry-pi-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-10-27-desintermediando-la-educacion-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-10-27-desintermediando-la-educacion/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-10-27-desintermediando-la-educacion-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-10-29-destruccion-electronica-i-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-10-29-destruccion-electronica-i/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-10-29-destruccion-electronica-i-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-11-03-modelado-de-un-edificio-ii-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-11-03-modelado-de-un-edificio-ii/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-11-03-modelado-de-un-edificio-ii-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-12-01-control-aplicado-a-la-electronica-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-12-01-control-aplicado-a-la-electronica/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-12-01-control-aplicado-a-la-electronica-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-12-07-muerte-al-datasheet-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-12-07-muerte-al-datasheet/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-12-07-muerte-al-datasheet-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-12-08-gnu-health-se-implantara-en-jamaica-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-12-08-gnu-health-se-implantara-en-jamaica/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-12-08-gnu-health-se-implantara-en-jamaica-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-12-26-monitorizar-la-temperatura-desde-raspberry-pi-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2013-12-26-monitorizar-la-temperatura-desde-raspberry-pi/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2013-12-26-monitorizar-la-temperatura-desde-raspberry-pi-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2014-01-12-joven-y-libre-aventurero-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2014-01-12-joven-y-libre-aventurero/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2014-01-12-joven-y-libre-aventurero-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2014-01-22-accediendo-al-sistema-de-archivos-de-raspberry-pi-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2014-01-22-accediendo-al-sistema-de-archivos-de-raspberry-pi/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2014-01-22-accediendo-al-sistema-de-archivos-de-raspberry-pi-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2014-02-17-el-paradigma-pasivo-frente-al-activo-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2014-02-17-el-paradigma-pasivo-frente-al-activo/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2014-02-17-el-paradigma-pasivo-frente-al-activo-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2014-03-09-mega-ciudades-estado-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2014-03-09-mega-ciudades-estado/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2014-03-09-mega-ciudades-estado-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2014-03-24-me-vuelvo-a-kde-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2014-03-24-me-vuelvo-a-kde/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2014-03-24-me-vuelvo-a-kde-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2014-04-13-red-de-vehiculo-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2014-04-13-red-de-vehiculo/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2014-04-13-red-de-vehiculo-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2014-05-18-integrar-en-linux-las-notificaciones-de-firefox-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2014-05-18-integrar-en-linux-las-notificaciones-de-firefox/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2014-05-18-integrar-en-linux-las-notificaciones-de-firefox-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2014-07-25-el-linux-que-se-viene-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2014-07-25-el-linux-que-se-viene/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2014-07-25-el-linux-que-se-viene-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2014-08-25-el-sentido-de-las-enciclopedias-centralizadas-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2014-08-25-el-sentido-de-las-enciclopedias-centralizadas/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2014-08-25-el-sentido-de-las-enciclopedias-centralizadas-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2014-11-09-robots-por-fin-en-el-hogar-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2014-11-09-robots-por-fin-en-el-hogar/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2014-11-09-robots-por-fin-en-el-hogar-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-01-18-pagina-de-api-del-blog-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2015-01-18-pagina-de-api-del-blog/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-01-18-pagina-de-api-del-blog-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-01-20-fiasco-del-plugin-webmentions-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2015-01-20-fiasco-del-plugin-webmentions/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-01-20-fiasco-del-plugin-webmentions-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-01-25-los-mapas-pre-estado-son-diferentes-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2015-01-25-los-mapas-pre-estado-son-diferentes/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-01-25-los-mapas-pre-estado-son-diferentes-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-02-15-innovaciones-para-un-cliente-rss-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2015-02-15-innovaciones-para-un-cliente-rss/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-02-15-innovaciones-para-un-cliente-rss-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-03-27-gnu-social-followers-widget-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2015-03-27-gnu-social-followers-widget/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-03-27-gnu-social-followers-widget-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-03-29-como-sobrevivir-sin-datos-en-tu-smartphone-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2015-03-29-como-sobrevivir-sin-datos-en-tu-smartphone/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-03-29-como-sobrevivir-sin-datos-en-tu-smartphone-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-04-12-herramientas-para-mapas-libres-y-colaborativas-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2015-04-12-herramientas-para-mapas-libres-y-colaborativas/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-04-12-herramientas-para-mapas-libres-y-colaborativas-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-05-09-plasma-5-como-ubuntu-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2015-05-09-plasma-5-como-ubuntu/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-05-09-plasma-5-como-ubuntu-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-08-05-automatizacion-libre-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2015-08-05-automatizacion-libre/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-08-05-automatizacion-libre-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-09-08-que-no-es-arte-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2015-09-08-que-no-es-arte/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-09-08-que-no-es-arte-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-09-19-nueva-placa-st-nucleo-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2015-09-19-nueva-placa-st-nucleo/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-09-19-nueva-placa-st-nucleo-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-09-30-nos-vemos-en-el-somero-2015-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2015-09-30-nos-vemos-en-el-somero-2015/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-09-30-nos-vemos-en-el-somero-2015-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-10-04-como-cambiar-la-industria-de-la-musica-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2015-10-04-como-cambiar-la-industria-de-la-musica/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-10-04-como-cambiar-la-industria-de-la-musica-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-10-17-ventana-a-nuevos-mundos-somero-2015-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2015-10-17-ventana-a-nuevos-mundos-somero-2015/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-10-17-ventana-a-nuevos-mundos-somero-2015-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-11-01-entramado-recibidor-del-club-de-las-indias-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2015-11-01-entramado-recibidor-del-club-de-las-indias/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-11-01-entramado-recibidor-del-club-de-las-indias-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-11-07-redes-distribuidas-crecimiento-y-captura-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2015-11-07-redes-distribuidas-crecimiento-y-captura/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-11-07-redes-distribuidas-crecimiento-y-captura-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-11-08-barreras-de-pago-en-estandares-supuestamente-abiertos-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2015-11-08-barreras-de-pago-en-estandares-supuestamente-abiertos/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-11-08-barreras-de-pago-en-estandares-supuestamente-abiertos-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-12-01-gnu-social-la-plataforma-social-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2015-12-01-gnu-social-la-plataforma-social/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-12-01-gnu-social-la-plataforma-social-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-12-27-hay-vida-tras-firefox-os-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2015-12-27-hay-vida-tras-firefox-os/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2015-12-27-hay-vida-tras-firefox-os-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2016-01-07-apt-al-estilo-moderno-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2016-01-07-apt-al-estilo-moderno/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2016-01-07-apt-al-estilo-moderno-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2016-01-13-persona-ha-muerto-larga-vida-a-openid-connect-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2016-01-13-persona-ha-muerto-larga-vida-a-openid-connect/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2016-01-13-persona-ha-muerto-larga-vida-a-openid-connect-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2016-02-07-red-distribuida-no-es-malla-completa-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2016-02-07-red-distribuida-no-es-malla-completa/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2016-02-07-red-distribuida-no-es-malla-completa-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2016-02-27-cifra-tus-comunicaciones-moviles-con-openpgp-1-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2016-02-27-cifra-tus-comunicaciones-moviles-con-openpgp-1/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2016-02-27-cifra-tus-comunicaciones-moviles-con-openpgp-1-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2016-04-16-comunitarismo-para-salir-del-capitalismo-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2016-04-16-comunitarismo-para-salir-del-capitalismo/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2016-04-16-comunitarismo-para-salir-del-capitalismo-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2016-04-24-mensajes-privados-en-gnu-social-y-su-federacion-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2016-04-24-mensajes-privados-en-gnu-social-y-su-federacion/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2016-04-24-mensajes-privados-en-gnu-social-y-su-federacion-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2016-06-04-como-instalar-una-raspberry-pi-sin-conectar-una-pantalla-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2016-06-04-como-instalar-una-raspberry-pi-sin-conectar-una-pantalla/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2016-06-04-como-instalar-una-raspberry-pi-sin-conectar-una-pantalla-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2016-07-07-demonios-del-sistema-y-el-sexo-de-los-angeles-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2016-07-07-demonios-del-sistema-y-el-sexo-de-los-angeles/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2016-07-07-demonios-del-sistema-y-el-sexo-de-los-angeles-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2016-07-09-honorables-y-existencialistas-objetos-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2016-07-09-honorables-y-existencialistas-objetos/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2016-07-09-honorables-y-existencialistas-objetos-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2016-08-20-borrador-de-open-badges-para-gnu-social-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2016-08-20-borrador-de-open-badges-para-gnu-social/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2016-08-20-borrador-de-open-badges-para-gnu-social-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2016-09-24-ser-progresista-en-un-mundo-sin-progreso-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2016-09-24-ser-progresista-en-un-mundo-sin-progreso/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2016-09-24-ser-progresista-en-un-mundo-sin-progreso-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2016-10-22-una-mirada-atras-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2016-10-22-una-mirada-atras/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2016-10-22-una-mirada-atras-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2016-12-18-no-comprar-local-no-salvara-el-mundo-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2016-12-18-no-comprar-local-no-salvara-el-mundo/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2016-12-18-no-comprar-local-no-salvara-el-mundo-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2017-01-13-ticket-virtual-api-y-rsc-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2017-01-13-ticket-virtual-api-y-rsc/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2017-01-13-ticket-virtual-api-y-rsc-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2017-02-02-el-sorprendente-ser-humano-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2017-02-02-el-sorprendente-ser-humano/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2017-02-02-el-sorprendente-ser-humano-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2017-02-07-integracion-de-jack-y-pulseaudio-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2017-02-07-integracion-de-jack-y-pulseaudio/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2017-02-07-integracion-de-jack-y-pulseaudio-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2017-02-11-settle-up-un-bote-virtual-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2017-02-11-settle-up-un-bote-virtual/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2017-02-11-settle-up-un-bote-virtual-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2017-04-13-ubuntu-hacia-la-autentica-convergencia-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2017-04-13-ubuntu-hacia-la-autentica-convergencia/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2017-04-13-ubuntu-hacia-la-autentica-convergencia-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2017-04-17-los-errores-de-persona-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2017-04-17-los-errores-de-persona/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2017-04-17-los-errores-de-persona-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2017-06-22-servidor-de-musica-casero-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2017-06-22-servidor-de-musica-casero/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2017-06-22-servidor-de-musica-casero-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2017-08-29-dub-los-otros-hackers-de-los-70-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2017-08-29-dub-los-otros-hackers-de-los-70/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2017-08-29-dub-los-otros-hackers-de-los-70-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2017-09-30-platon-y-la-programacion-orientada-a-objetos-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2017-09-30-platon-y-la-programacion-orientada-a-objetos/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2017-09-30-platon-y-la-programacion-orientada-a-objetos-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2017-10-26-kubuntu-con-wayland-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2017-10-26-kubuntu-con-wayland/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2017-10-26-kubuntu-con-wayland-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2018-05-26-bolsas-vs-tickets-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2018-05-26-bolsas-vs-tickets/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2018-05-26-bolsas-vs-tickets-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2018-08-16-configurabilidad-y-politica-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2018-08-16-configurabilidad-y-politica/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2018-08-16-configurabilidad-y-politica-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2018-08-29-efecto-translucido-en-konsole-y-yakuake-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2018-08-29-efecto-translucido-en-konsole-y-yakuake/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2018-08-29-efecto-translucido-en-konsole-y-yakuake-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2018-11-04-el-equipo-de-qt-cesa-qbs-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2018-11-04-el-equipo-de-qt-cesa-qbs/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2018-11-04-el-equipo-de-qt-cesa-qbs-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2018-11-11-el-poder-de-la-banca-en-europa-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2018-11-11-el-poder-de-la-banca-en-europa/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2018-11-11-el-poder-de-la-banca-en-europa-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2018-11-22-repensando-mi-activismo-en-software-libre-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2018-11-22-repensando-mi-activismo-en-software-libre/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2018-11-22-repensando-mi-activismo-en-software-libre-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2019-02-02-la-tendencia-oculta-en-el-conflicto-taxi-vtc-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2019-02-02-la-tendencia-oculta-en-el-conflicto-taxi-vtc/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2019-02-02-la-tendencia-oculta-en-el-conflicto-taxi-vtc-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2019-05-25-el-liberalismo-y-los-eeuu-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2019-05-25-el-liberalismo-y-los-eeuu/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2019-05-25-el-liberalismo-y-los-eeuu-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2019-06-23-hiperconectado-centralizado-fugaz-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2019-06-23-hiperconectado-centralizado-fugaz/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2019-06-23-hiperconectado-centralizado-fugaz-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2019-07-17-facebook-y-el-monopolio-de-la-moneda-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2019-07-17-facebook-y-el-monopolio-de-la-moneda/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2019-07-17-facebook-y-el-monopolio-de-la-moneda-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2019-10-10-joker-y-gini-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2019-10-10-joker-y-gini/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2019-10-10-joker-y-gini-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2019-11-23-mi-viaje-por-la-consola-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2019-11-23-mi-viaje-por-la-consola/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2019-11-23-mi-viaje-por-la-consola-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2020-01-18-3-claves-para-entender-la-decada-que-entra-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2020-01-18-3-claves-para-entender-la-decada-que-entra/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2020-01-18-3-claves-para-entender-la-decada-que-entra-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2020-04-21-flatpak-en-wayland-con-sddm-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2020-04-21-flatpak-en-wayland-con-sddm/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2020-04-21-flatpak-en-wayland-con-sddm-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2020-05-17-como-depurar-pulseaudio-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2020-05-17-como-depurar-pulseaudio/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2020-05-17-como-depurar-pulseaudio-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2022-04-10-el-metaverso-es-una-maniobra-de-marketing-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2022-04-10-el-metaverso-es-una-maniobra-de-marketing/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2022-04-10-el-metaverso-es-una-maniobra-de-marketing-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2022-04-28-la-caida-del-modelo-suscripcion-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2022-04-28-la-caida-del-modelo-suscripcion/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2022-04-28-la-caida-del-modelo-suscripcion-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2023-02-05-cambios-en-el-blog-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2023-02-05-cambios-en-el-blog/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2023-02-05-cambios-en-el-blog-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2023-02-10-como-instalar-nvidia-docker-2-en-debian-12-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2023-02-10-como-instalar-nvidia-docker2-en-debian-12/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2023-02-10-como-instalar-nvidia-docker-2-en-debian-12-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2023-02-26-como-montar-una-particion-de-raspberry-pi-en-linux-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2023-02-26-como-montar-una-particion-de-raspberry-pi-en-linux/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2023-02-26-como-montar-una-particion-de-raspberry-pi-en-linux-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2023-04-04-la-inteligencia-artificial-y-el-multiverso-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2023-04-04-la-inteligencia-artificial-y-el-multiverso/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2023-04-04-la-inteligencia-artificial-y-el-multiverso-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2023-05-20-el-proximo-openai-no-sera-europeo-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2023-05-20-el-proximo-openai-no-sera-europeo/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2023-05-20-el-proximo-openai-no-sera-europeo-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2023-06-12-soporte-linux-para-openbb-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2023-06-12-soporte-linux-para-openbb/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2023-06-12-soporte-linux-para-openbb-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2023-09-24-luz-de-cortesia-con-home-assistant-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2023-09-24-luz-de-cortesia-con-home-assistant/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2023-09-24-luz-de-cortesia-con-home-assistant-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2024-01-02-copyright-en-la-era-de-las-ias-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2024-01-02-copyright-en-la-era-de-las-ias/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2024-01-02-copyright-en-la-era-de-las-ias-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2024-01-14-por-la-websencilla-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2024-01-14-por-la-websencilla/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2024-01-14-por-la-websencilla-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2024-01-31-ibai-no-tiene-una-comunidad-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2024-01-31-ibai-no-tiene-una-comunidad/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2024-01-31-ibai-no-tiene-una-comunidad-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2024-05-05-la-comunidad-es-la-forma-optima-de-compartir-servicios-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2024-05-05-la-comunidad-es-la-forma-optima-de-compartir-servicios/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2024-05-05-la-comunidad-es-la-forma-optima-de-compartir-servicios-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2024-06-21-en-busca-de-enlaces-bidireccionales-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2024-06-21-en-busca-de-enlaces-bidireccionales/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2024-06-21-en-busca-de-enlaces-bidireccionales-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2024-07-10-webmentions-ahora-si-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/content/blog/2024-07-10-webmentions-ahora-si/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-buildhome-repo-content-blog-2024-07-10-webmentions-ahora-si-index-md" */)
}

